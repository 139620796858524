<template>
  <div>
    <v-card class="rounded-0">
      <v-navigation-drawer
        app
        class="pt-4"
        color="secondary"
        v-model="drawer"
        :mini-variant="miniDrawer"
        permanent
      >
        <v-list-item class="px-2" :to="{ name: 'operations' }" link>
          <v-list-item-avatar>
            <v-img v-if="miniDrawer" src="@/assets/images/simbolo.png"></v-img>
          </v-list-item-avatar>

          <v-list-item-title class="ml-n10">
            <v-img src="@/assets/images/bionix.png"></v-img>
          </v-list-item-title>

          <v-btn icon>
            <!-- <v-icon>mdi-chevron-left</v-icon> -->
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="(item, i) in items"
            :to="{ name: item.route }"
            :key="`menu${i}`"
            active-class="highlighted"
            link
          >
            <v-list-item-icon class="me-6" :style="{ width: '24px' }">
              <img :src="isActive(item.route) ? item.activeIcon : item.icon" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="white--text">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <v-list>
            <v-list-item @click="exit" link>
              <v-list-item-icon class="me-6" :style="{ width: '24px' }">
                <v-btn icon>
                  <v-icon color="white">mdi-logout</v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text">{{
                  $t("common.logout")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
    </v-card>
    <v-main>
      <v-toolbar
        color="primary"
        dense
        class="accent--text text-h5 mb-2"
        v-if="pageTitle"
        flat
      >
        <v-app-bar-nav-icon
          @click="miniDrawer = !miniDrawer"
        ></v-app-bar-nav-icon>
        <b>{{ $t("app-title") }}</b>
        <v-divider class="mx-4" width="100" vertical></v-divider>
        {{ pageTitle }}
      </v-toolbar>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import i18n from "@/i18n";

export default {
  name: "admin-layout",
  data() {
    return {
      drawer: true,
      items: [
        {
          title: i18n.tc("operation.title", 2),
          icon: require("@/assets/images/operation-w.svg"),
          activeIcon: require("@/assets/images/operation.svg"),
          route: "operations",
        },
        {
          title: i18n.tc("controller.title", 2),
          icon: require("@/assets/images/controller-w.svg"),
          activeIcon: require("@/assets/images/controller.svg"),
          route: "clients",
        }
      ],
      miniDrawer: true,
    };
  },
  computed: {
    pageTitle() {
      return this.$route.meta?.title;
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    isActive(to) {
      return this.$router.currentRoute.path.includes(to);
    },
    exit() {
      this.logout();
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style></style>
